exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accessories-banner-tsx": () => import("./../../../src/pages/accessories-banner.tsx" /* webpackChunkName: "component---src-pages-accessories-banner-tsx" */),
  "component---src-pages-accessories-menu-tsx": () => import("./../../../src/pages/accessories-menu.tsx" /* webpackChunkName: "component---src-pages-accessories-menu-tsx" */),
  "component---src-pages-advantage-tsx": () => import("./../../../src/pages/advantage.tsx" /* webpackChunkName: "component---src-pages-advantage-tsx" */),
  "component---src-pages-affiliate-tsx": () => import("./../../../src/pages/affiliate.tsx" /* webpackChunkName: "component---src-pages-affiliate-tsx" */),
  "component---src-pages-annual-bundle-upgrade-2-tsx": () => import("./../../../src/pages/annual-bundle-upgrade-2.tsx" /* webpackChunkName: "component---src-pages-annual-bundle-upgrade-2-tsx" */),
  "component---src-pages-annual-bundle-upgrade-tsx": () => import("./../../../src/pages/annual-bundle-upgrade.tsx" /* webpackChunkName: "component---src-pages-annual-bundle-upgrade-tsx" */),
  "component---src-pages-assembly-farmstand-tsx": () => import("./../../../src/pages/assembly-farmstand.tsx" /* webpackChunkName: "component---src-pages-assembly-farmstand-tsx" */),
  "component---src-pages-assembly-nook-tsx": () => import("./../../../src/pages/assembly-nook.tsx" /* webpackChunkName: "component---src-pages-assembly-nook-tsx" */),
  "component---src-pages-assembly-tsx": () => import("./../../../src/pages/assembly.tsx" /* webpackChunkName: "component---src-pages-assembly-tsx" */),
  "component---src-pages-autofill-tsx": () => import("./../../../src/pages/autofill.tsx" /* webpackChunkName: "component---src-pages-autofill-tsx" */),
  "component---src-pages-canada-home-tsx": () => import("./../../../src/pages/canada-home.tsx" /* webpackChunkName: "component---src-pages-canada-home-tsx" */),
  "component---src-pages-claim-your-referral-tsx": () => import("./../../../src/pages/claim-your-referral.tsx" /* webpackChunkName: "component---src-pages-claim-your-referral-tsx" */),
  "component---src-pages-counter-produce-2-tsx": () => import("./../../../src/pages/counter-produce-2.tsx" /* webpackChunkName: "component---src-pages-counter-produce-2-tsx" */),
  "component---src-pages-counter-produce-tsx": () => import("./../../../src/pages/counter-produce.tsx" /* webpackChunkName: "component---src-pages-counter-produce-tsx" */),
  "component---src-pages-education-contact-tsx": () => import("./../../../src/pages/education-contact.tsx" /* webpackChunkName: "component---src-pages-education-contact-tsx" */),
  "component---src-pages-element-hotels-offer-tsx": () => import("./../../../src/pages/element-hotels-offer.tsx" /* webpackChunkName: "component---src-pages-element-hotels-offer-tsx" */),
  "component---src-pages-element-hotels-tsx": () => import("./../../../src/pages/element-hotels.tsx" /* webpackChunkName: "component---src-pages-element-hotels-tsx" */),
  "component---src-pages-farmstand-collection-indoor-tsx": () => import("./../../../src/pages/farmstand-collection-indoor.tsx" /* webpackChunkName: "component---src-pages-farmstand-collection-indoor-tsx" */),
  "component---src-pages-farmstand-collection-nook-tsx": () => import("./../../../src/pages/farmstand-collection-nook.tsx" /* webpackChunkName: "component---src-pages-farmstand-collection-nook-tsx" */),
  "component---src-pages-farmstand-collection-outdoor-tsx": () => import("./../../../src/pages/farmstand-collection-outdoor.tsx" /* webpackChunkName: "component---src-pages-farmstand-collection-outdoor-tsx" */),
  "component---src-pages-farmstand-fresh-tsx": () => import("./../../../src/pages/farmstand-fresh.tsx" /* webpackChunkName: "component---src-pages-farmstand-fresh-tsx" */),
  "component---src-pages-farmstand-indoor-individual-tsx": () => import("./../../../src/pages/farmstand-indoor-individual.tsx" /* webpackChunkName: "component---src-pages-farmstand-indoor-individual-tsx" */),
  "component---src-pages-farmstand-menu-tsx": () => import("./../../../src/pages/farmstand-menu.tsx" /* webpackChunkName: "component---src-pages-farmstand-menu-tsx" */),
  "component---src-pages-farmstand-outdoor-individual-tsx": () => import("./../../../src/pages/farmstand-outdoor-individual.tsx" /* webpackChunkName: "component---src-pages-farmstand-outdoor-individual-tsx" */),
  "component---src-pages-farmstand-registration-tsx": () => import("./../../../src/pages/farmstand-registration.tsx" /* webpackChunkName: "component---src-pages-farmstand-registration-tsx" */),
  "component---src-pages-farmstand-survey-indoor-tsx": () => import("./../../../src/pages/farmstand-survey-indoor.tsx" /* webpackChunkName: "component---src-pages-farmstand-survey-indoor-tsx" */),
  "component---src-pages-farmstand-survey-outdoor-tsx": () => import("./../../../src/pages/farmstand-survey-outdoor.tsx" /* webpackChunkName: "component---src-pages-farmstand-survey-outdoor-tsx" */),
  "component---src-pages-fathers-day-tsx": () => import("./../../../src/pages/fathers-day.tsx" /* webpackChunkName: "component---src-pages-fathers-day-tsx" */),
  "component---src-pages-gardena-canyon-tsx": () => import("./../../../src/pages/gardena-canyon.tsx" /* webpackChunkName: "component---src-pages-gardena-canyon-tsx" */),
  "component---src-pages-gardena-jaskrit-tsx": () => import("./../../../src/pages/gardena-jaskrit.tsx" /* webpackChunkName: "component---src-pages-gardena-jaskrit-tsx" */),
  "component---src-pages-gardena-valerie-tsx": () => import("./../../../src/pages/gardena-valerie.tsx" /* webpackChunkName: "component---src-pages-gardena-valerie-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-menu-tsx": () => import("./../../../src/pages/journal-menu.tsx" /* webpackChunkName: "component---src-pages-journal-menu-tsx" */),
  "component---src-pages-meet-the-farmstand-nook-tsx": () => import("./../../../src/pages/meet-the-farmstand-nook.tsx" /* webpackChunkName: "component---src-pages-meet-the-farmstand-nook-tsx" */),
  "component---src-pages-nook-refurbished-tsx": () => import("./../../../src/pages/nook-refurbished.tsx" /* webpackChunkName: "component---src-pages-nook-refurbished-tsx" */),
  "component---src-pages-outdoor-fs-pdp-tsx": () => import("./../../../src/pages/outdoor-fs-pdp.tsx" /* webpackChunkName: "component---src-pages-outdoor-fs-pdp-tsx" */),
  "component---src-pages-post-purchase-upsell-bundle-tsx": () => import("./../../../src/pages/post-purchase-upsell-bundle.tsx" /* webpackChunkName: "component---src-pages-post-purchase-upsell-bundle-tsx" */),
  "component---src-pages-post-purchase-upsell-tsx": () => import("./../../../src/pages/post-purchase-upsell.tsx" /* webpackChunkName: "component---src-pages-post-purchase-upsell-tsx" */),
  "component---src-pages-promo-banner-labor-day-tsx": () => import("./../../../src/pages/promo-banner-labor-day.tsx" /* webpackChunkName: "component---src-pages-promo-banner-labor-day-tsx" */),
  "component---src-pages-refer-a-friend-tsx": () => import("./../../../src/pages/refer-a-friend.tsx" /* webpackChunkName: "component---src-pages-refer-a-friend-tsx" */),
  "component---src-pages-refurbished-farmstand-indoor-individual-tsx": () => import("./../../../src/pages/refurbished-farmstand-indoor-individual.tsx" /* webpackChunkName: "component---src-pages-refurbished-farmstand-indoor-individual-tsx" */),
  "component---src-pages-refurbished-indoor-farmstand-tsx": () => import("./../../../src/pages/refurbished-indoor-farmstand.tsx" /* webpackChunkName: "component---src-pages-refurbished-indoor-farmstand-tsx" */),
  "component---src-pages-refurbished-outdoor-farmstand-tsx": () => import("./../../../src/pages/refurbished-outdoor-farmstand.tsx" /* webpackChunkName: "component---src-pages-refurbished-outdoor-farmstand-tsx" */),
  "component---src-pages-seedling-redemption-2-tsx": () => import("./../../../src/pages/seedling-redemption-2.tsx" /* webpackChunkName: "component---src-pages-seedling-redemption-2-tsx" */),
  "component---src-pages-seedling-redemption-tsx": () => import("./../../../src/pages/seedling-redemption.tsx" /* webpackChunkName: "component---src-pages-seedling-redemption-tsx" */),
  "component---src-pages-seedlings-banner-tsx": () => import("./../../../src/pages/seedlings-banner.tsx" /* webpackChunkName: "component---src-pages-seedlings-banner-tsx" */),
  "component---src-pages-seedlings-menu-2-tsx": () => import("./../../../src/pages/seedlings-menu-2.tsx" /* webpackChunkName: "component---src-pages-seedlings-menu-2-tsx" */),
  "component---src-pages-seedlings-menu-tsx": () => import("./../../../src/pages/seedlings-menu.tsx" /* webpackChunkName: "component---src-pages-seedlings-menu-tsx" */),
  "component---src-pages-seedlings-tsx": () => import("./../../../src/pages/seedlings.tsx" /* webpackChunkName: "component---src-pages-seedlings-tsx" */),
  "component---src-pages-shop-farmstands-tsx": () => import("./../../../src/pages/shop-farmstands.tsx" /* webpackChunkName: "component---src-pages-shop-farmstands-tsx" */)
}

